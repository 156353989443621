import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import * as Icon from "react-feather"
import { Link } from "gatsby"

import BlogDetailsImg from "../assets/images/blog-image/blog-details.jpg"

const BlogDetails = () => (
  <Layout>
    <SEO title="Blog Details" />

    <Navbar />

    <PageBanner pageTitle="Blog Details" />

    <div>
      <div className="container">
        <div className="">
          <div className="">
            <div className="blog-details-desc">
              <div className="article-image">
                <img src={BlogDetailsImg} alt="image" />
              </div>

              <div className="article-content">
                <div className="entry-meta">
                  <ul>
                    <li>
                      <Icon.Clock /> September 31, 2021
                    </li>
                    <li>
                      <Icon.User /> <Link to="#">Steven Smith</Link>
                    </li>
                  </ul>
                </div>

                <h2>The security risks of changing package owners</h2>

                <p>
                  Quuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Neque porro quia non numquam eius modi tempora
                  incidunt ut labore et dolore magnam dolor sit amet,
                  consectetur adipisicing.
                </p>

                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in sed quia non numquam eius modi
                  tempora incidunt ut labore et dolore magnam aliquam quaerat
                  voluptatem.
                </p>

                <blockquote>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>

                  <cite>Tom Cruise</cite>
                </blockquote>

                <p>
                  Quuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Neque porro quia non numquam eius modi tempora
                  incidunt ut labore et dolore magnam dolor sit amet,
                  consectetur adipisicing.
                </p>

                {/* <ul className="block-gallery columns-3">
                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src={BlogImg7} alt="image" />
                                        </figure>
                                    </li>

                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src={BlogImg9} alt="image" />
                                        </figure>
                                    </li>

                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src={BlogImg8} alt="image" />
                                        </figure>
                                    </li>
                                </ul> */}

                <h3>Four major elements that we offer:</h3>

                <ul className="features-list">
                  <li>
                    <Icon.Check /> Scientific Skills For getting a better result
                  </li>
                  <li>
                    <Icon.Check /> Communication Skills to getting in touch
                  </li>
                  <li>
                    <Icon.Check /> A Career Overview opportunity Available
                  </li>
                  <li>
                    <Icon.Check /> A good Work Environment For work
                  </li>
                </ul>

                <h3>Setting the mood with incense</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in sed quia non numquam eius modi
                  tempora incidunt ut labore et dolore magnam aliquam quaerat
                  voluptatem.
                </p>

                <h3>The Rise Of Smarketing And Why You Need It</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud.
                </p>
              </div>

              <div className="article-footer">
                <div className="article-tags">
                  <Link to="#">Fashion</Link>
                  <Link to="#">Smart</Link>
                  <Link to="#">Marketing</Link>
                  <Link to="#">Games</Link>
                  <Link to="#">Travel</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default BlogDetails
